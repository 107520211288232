//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainLayout from '../layouts/Main.vue'
export default {
  components: {
    MainLayout
  }
}
